import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  setPartnerId,
  setPartnerRevision,
  updatePartnerRevision,
} from '@stores/partner/partner-revision/partner-revision.actions';
import { partnerId } from '@stores/partner/partner-revision/partner-revision.selectors';
import { PartnerWebservice } from '@webservices/partner-api/partner.webservice';
import { filter, map, switchMap } from 'rxjs';

export const setPartnerRevisionState$ = createEffect(
  (actions$ = inject(Actions), partnerWebservice = inject(PartnerWebservice)) =>
    actions$.pipe(
      ofType(setPartnerId),
      map((action) => action.partnerId),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => partnerWebservice.getPartnerRevision({ partnerId })),
      map((partnerRevision) => setPartnerRevision({ partnerRevision }))
    ),
  { functional: true }
);

export const updatePartnerRevisionState$ = createEffect(
  (actions$ = inject(Actions), partnerWebservice = inject(PartnerWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(updatePartnerRevision),
      switchMap(() => store.select(partnerId)),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => partnerWebservice.getPartnerRevision({ partnerId })),
      map((partnerRevision) => setPartnerRevision({ partnerRevision }))
    ),
  { functional: true }
);
